body {
  margin: 0;
  background-color: #f7f8f6;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(160, 160, 160);
}

*::-webkit-scrollbar-track {
  background-color: #f7f8f6;
}

*::-webkit-scrollbar {
  width: 10px;
  /* overrides material-table */
  height: 10px !important;
  background-color: #f7f8f6;
}

*::-webkit-scrollbar-thumb {
  /* overrides material-table */
  border-radius: 10px !important;
  background-color: rgb(192, 192, 192) !important;
  border: 0 !important;
}

*[tabindex]:focus {
  outline: 0;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  opacity: 1;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: background-image 150ms;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' aria-hidden='true' fill='%23ff4040' focusable='false' %3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E");
}

input[type='search']::-webkit-search-cancel-button:hover {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' aria-hidden='true' fill='%23d00000' focusable='false' %3E%3Cpath d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'%3E%3C/path%3E%3C/svg%3E");
}
