.votingPortalTheme {
    --landing-page-text-accent-color: #4019aa;
    --s-title-text-color: #000;
    --s-background-color: #ced8f4;
    --s-body-text-color: #000;
    --main-button-background-color: #411aab;
    --main-button-text-color: #fff;
}
.accessSection {
    --landing-page-text-accent-color: #e77f3d;
}
.mitigateSection {
    --landing-page-text-accent-color: #411aab;
}
.login {
    margin-top: 5rem;
    margin-right: 5rem;
}